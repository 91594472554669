import {ar} from './ar.js'
import {en} from './en.js'
import LocalizedStrings from 'react-localization';
let strings = new LocalizedStrings({ar, en });

class Localizer {
    static setLanguage(lang) {
        this.strings.setLanguage(lang)
    }

    static getLanguage() {
       return  this.strings.getLanguage().toLowerCase()
    }

    static getDeviceLanguage(){
        // console.log("this.strings.getInterfaceLanguage()", this.strings.getInterfaceLanguage().toLowerCase().startsWith("ar") )
       let lang =this.strings.getInterfaceLanguage().toLowerCase().startsWith("ar") ? "ar" : "en"
    //    this.setLanguage(lang)
       return lang
    }

    static langToSwitch() { // returns opposet
        const lang= this.getLanguage().startsWith("ar") ? "en" : "ar"
        return lang
    }
}

Localizer.strings=strings

export default Localizer
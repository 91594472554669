import React, { useEffect, useState } from "react";
import Skills from '../skills';
import About from '../aboutme';
import Contact from '../contactme';
import Work from '../work';

import './index.css'
import Header from "./Header";
import Localizer from "../../locales";

export default ( props )=> {
    
    const { page } = props

    const [locale, updateLocaleInstance]= useState(Localizer.strings)

    
    useEffect(()=> {updateLocaleInstance(Localizer.strings)}, [props.lang])

    const renderPage= ()=> {

        switch (page) {

            case 'SKILLS':
                return <Skills locale={locale} {...props} >{<Header backTitle={locale.nav.title} title={locale.skills.title} {...props} />}</Skills>
            
            case 'ABOUT':
                return <About locale={locale} {...props} >{<Header  backTitle={locale.nav.title}  title={locale.about.title}  {...props} />}</About>
            
            case 'CONTACT':
                return <Contact locale={locale} {...props} > <Header  backTitle={locale.nav.title}  title={locale.contact.title}  {...props} /> </Contact>
            
            case 'WORK':
                return <Work locale={locale} {...props} > {<Header backTitle={locale.nav.title}  title={locale.work.title} {...props} />} </Work>
                
            default: break;
        }
    }
  
    return(
        renderPage()
    )
}
// index.js
import React from 'react'

import './index.css'

import Localizer from '../../locales'

const Footer = (props) =>{


   return(

    <footer className="site-footer">
        {/* <div className="flex-row" style={{justifyContent:"space-around", width:"100%"}}> */}

		    {/* <div className="flex-col">
	            <ul className="site-links">
	              <li><a className="" href="#">Search freelancers</a></li>
	              <li><a className="dribbble" href="#">Serach Jobs</a></li>
	              <li><a className="linkedin" href="#">My Projects</a></li>
				  <li><a className="twitter" href="#">Dashboard</a></li>
   
	            </ul>
	        </div> */}

	        {/* <div className="flex-col">
	            <ul className="site-links">
	              <li><a className="" href="#">Home</a></li>
	              <li><a className="dribbble" href="#">TOS</a></li>
	              <li><a className="linkedin" href="#">Contact us</a></li>
				  <li><a className="twitter" href="#">About us</a></li>
   
	            </ul>
	        </div> */}

	        {/* <div className="flex-col">
	            <ul className="social-icons">
	              <li><a className="facebook" href="#"><i className="fa fa-facebook"></i></a></li>
	              <li><a className="twitter" href="#"><i className="fa fa-twitter"></i></a></li>
	              <li><a className="dribbble" href="#"><i className="fa fa-dribbble"></i></a></li>
	              <li><a className="linkedin" href="#"><i className="fa fa-linkedin"></i></a></li>   
	            </ul>
	        </div> */}

        
        {/* </div> */}
		<div className="copyrights-wrapper">
	            <p className="copyright-text">
					{Localizer.strings.copyrights}
	            </p>
	        </div>
    </footer>
   )
}

export default Footer
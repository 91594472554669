import React from "react";
import './index.css'
import back from '../../back.png'
import PERSPECTIVE from './perspective.png'
import SOLVER from './solver.png'
import PLANNING from './planning.png'
// import photo from './test-photo.jpg'
import photo from './photo1.jpg'
export default ( props ) => {

    const dir= props.locale.lang.toLowerCase().startsWith("en") ? "ltr" :"rtl"

    return(
        <div className={`about-container ${props.className}`}>
            {props.children}
            <div className="who-section">
                <h2>{props.locale.about.who} </h2>
                <div dir={dir}>
                    <img src={photo}/>
                    <div className="me-paragraph">
                        <p>
                            {props.locale.about.p1}
                        </p>
                        <p>
                            {props.locale.about.p2}
                        </p>
                        <p>
                            {props.locale.about.p3}
                        </p>
                        <p>
                            {props.locale.about.p4}
                        </p>                    
                    </div>
                </div>
            </div>
            
            <div className="hire-section">
                
                <h2>{props.locale.about.why}</h2>
                
                {props.locale.about.why_intro}
                
                <div className="hire-items">
                    <div className="a-item"> 
                        <img src={PERSPECTIVE} />
                        <span>{props.locale.about.ap}</span>
                    </div>
                    <div className="a-item" >
                           <img src={PLANNING} />
                           <span>{props.locale.about.pp}</span>
                    </div>
                    <div className="a-item" >
                           <img src={SOLVER} />
                           <span>{props.locale.about.ps}</span>
                    </div> 
                    
                    {/* <span>continous Support</span> */}
                </div>
            </div>

        </div>
    )
}

import React from "react";
import './index.css'
import ITEM1 from './item1.png'
import ITEM2 from './item2.png'
import ITEM3 from './item3.png'

export default ( props ) => {

    const dir= props.locale.lang.toLowerCase().startsWith("en") ? "ltr" :"rtl"

    return(
        <div  className={`work-container ${props.className}` }>
            {props.children}

            <div dir={dir} className="work-items-container">
                <div className="work-item">
                    <div className="wi-body">
                        <img src={ITEM1} />
                        <div className="overlay"></div>
                    </div>
                    <div className="wi-footer">
                        <span className="title" >SYN Company</span>

                        <p>
                            {props.locale.work.item1}
                            <a target={"_blank"} href="https://softwareyouneed.herokuapp.com/" >{props.locale.work.view}</a>
                        </p>

                        <div className="wi-tags-row">
                            
                            <div className="wi-tag">
                                <span className="tag-label">Ruby on Rails</span>
                            </div>
                            
                            <div className="wi-tag">
                                <span className="tag-label">javascript</span>
                            </div>
                        

                            <div className="wi-tag">
                                <span className="tag-label">HTML5</span>
                            </div>

                            <div className="wi-tag">
                                <span className="tag-label">CSS3</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="work-item">
                    <div className="wi-body">
                        <img src={ITEM3} />
                        <div className="overlay"></div>
                    </div>
                    <div className="wi-footer">
                        <span className="title" >
                            Smartphone Finder
                        </span>

                        <p>
                            {props.locale.work.item2}
                            <a target={"_blank"} href="https://github.com/alrawi90/smartphone_finder" >{props.locale.work.view}</a>

                        </p>
                        
                        <div className="wi-tags-row">
                            
                            <div className="wi-tag">
                                <span className="tag-label">Ruby</span>
                            </div>
                            
                            <div className="wi-tag">
                                <span className="tag-label">Scraping</span>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="work-item">
                    <div className="wi-body">
                        <img src={ITEM2} />
                        <div className="overlay"></div>
                    </div>
                    <div className="wi-footer">
                        <span className="title" >Sev Market</span>

                        <p>
                            {props.locale.work.item3}
                            <a target={"_blank"} href="https://sevmarket.herokuapp.com/" >{props.locale.work.view}</a>

                        </p>
                    
                        <div className="wi-tags-row">
                            
                            <div className="wi-tag">
                                <span className="tag-label">Ruby on Rails</span>
                            </div>
                            
                            <div className="wi-tag">
                                <span className="tag-label">React.js</span>
                            </div>
                        

                            <div className="wi-tag">
                                <span className="tag-label">HTML5</span>
                            </div>

                            <div className="wi-tag">
                                <span className="tag-label">CSS3</span>
                            </div>
                        </div>
                    </div>
                </div>
{/* 
                <div className="work-item">
                    <img src={ITEM3} />
                    <div className="overlay"></div>
                    <span >Smartphone Finder</span>
                </div>

                <div className="work-item">
                    <img src={ITEM2} />
                    <div className="overlay"></div>
                    <span >Sev Market</span>
                </div> */}

            </div>
        </div>
    )
}
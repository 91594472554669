import React, { useEffect, useRef, useState } from "react";
// import Skills from '../skills'
// import About from '../aboutme'
// import Contact from '../contactme'
// import Work from '../work'
import './index.css'
import Helpers from '../../Helpers.js'

import Localizer from "../../locales";
export default ( props ) => {
    const {page, setPage}=  props    
    const [zIndexFix, fixIt]= useState('index-fix')

    const [containerClasses, setContainerClasses]= useState('')

    const [skillsClasses, setSkillsClasses]= useState('nav-item skills')

    const [locale, updateLocaleInstance]= useState(Localizer.strings)

    const skills= useRef(null)

    const about= useRef(null)

    const contact= useRef(null)

    const work= useRef(null)

    const nav= useRef(null)
   
    useEffect(()=> {updateLocaleInstance(Localizer.strings)}, [props.lang])

    const handelClick= (pageName)=> {
        
        switch (pageName) {
            case "SKILLS":
                if(Helpers.ifMobileOrTabDevice()){

                    onSkillsSelectedAnimationForMobile()

                } else {
                    onSkillsSelectedAnimation()
                }
                break;
            case "ABOUT":
                if(Helpers.ifMobileOrTabDevice()){
                    onAboutSelectedAnimationForMobile()
                } else {
                    onAboutSelectedAnimation()
                }
                break;

            case "CONTACT":
                if(Helpers.ifMobileOrTabDevice() ){
                    onContactSelectedAnimationForMobile()
                   
                } else {
                    onContactSelectedAnimation()
                }
                break;
            case "WORK":
                if(Helpers.ifMobileOrTabDevice()){
                   onWorksSelectedAnimationForMobile()
                }else {
                  onWorksSelectedAnimation()
                }
                break;
            default:
                break;
        }       
    }

    const onSkillsSelectedAnimation= ()=>{
            let skillsItem= skills.current;//document.querySelector(".skills")

            const selectItemEffect = [
                { transform: 'rotate(0) scale(1)', zIndex:"10", left:"-1%", },
                { transform: 'rotate(10deg) scale(1.1)', left:"-3%", },
                { transform: 'rotate(0) scale(1.1)', left:"-6%",  },
                {left:"-7%", zIndex:"10"},
                {left:"-10%", zIndex:"10"},
                {left:"-100%", zIndex:"10"},
                
              ];
              
              const timeing = {
                duration: 2000,
                iterations: 1,
              }
            //   setTimeout(()=>skillsItem.classList.add("hidden") , 2000)  
                        
              skillsItem.animate(selectItemEffect, timeing);
            //   setTimeout(()=>setPage("SKILLS"), 2000)  
            setPage("SKILLS")
              
              
    }
    
    const onSkillsSelectedAnimationForMobile= ()=>{
            let skillsItem= skills.current;//document.querySelector(".skills")
            
            const selectItemEffect = [
                { transform: 'rotate(0) scale(1) translateX(-1%)' },
                { transform: 'rotate(10deg) scale(1.1) translateX(-3%)'},
                { transform: 'rotate(0) scale(1.1) translateX(-6%)'  },
                { transform: 'translateX(-7%)'  },
                { transform: 'translateX(-10%)'  },
                { transform: 'translateX(-100%)'  },
          
                
              ];
              
              const timeing = {
                duration: 2000,
                iterations: 1,
              }
            //   setTimeout(()=>skillsItem.classList.add("hidden") , 2000)  
                        
              skillsItem.animate(selectItemEffect, timeing);
            //   setTimeout(()=>setPage("SKILLS"), 2000)  
            setPage("SKILLS")
            
              
              
    }
    
    const onAboutSelectedAnimation= ()=>{
        let aboutItem= about.current;// document.querySelector(".about")

        const selectItemEffect = [
            { transform: 'rotate(0deg) scale(1)', zIndex:"10", right:"-1%", },
            { transform: 'rotate(-10deg) scale(1.1)', right:"-3%", },
            { transform: 'rotate(0deg) scale(1.1)', right:"-6%",  },
            {right:"-7%", zIndex:"10"},
            {right:"-10%", zIndex:"10"},
            {right:"-100%", zIndex:"10"},
          ];
          
          const timeing = {
            duration: 2000,
            iterations: 1,
          }
        //   setTimeout(()=>skillsItem.classList.add("hidden") , 2000)  
                    
        aboutItem.animate(selectItemEffect, timeing);
        //   setTimeout(()=>setPage("SKILLS"), 2000)  
        setPage("ABOUT")
                    
    }
 
    const onAboutSelectedAnimationForMobile= ()=>{
        let aboutItem= about.current;//document.querySelector(".about")

        const selectItemEffect = [
            { transform: 'rotate(0) scale(1) translateX(1%)' },
            { transform: 'rotate(10deg) scale(1.1) translateX(3%)'},
            { transform: 'rotate(0) scale(1.1) translateX(6%)'  },
            { transform: 'translateX(7%)'  },
            { transform: 'translateX(10%)'  },
            { transform: 'translateX(100%)'  },
        
           
        ];
            
        const timeing = {
            duration: 2000,
            iterations: 1,
        }
                    
            aboutItem.animate(selectItemEffect, timeing);
        setPage("ABOUT")
              
    }
    
    const onWorksSelectedAnimation= ()=>{
        
        let item= work.current;//document.querySelector(".work")

        const selectItemEffect = [
            { transform: 'rotate(0deg) scale(1)', zIndex:"10", right:"-1%", },
            { transform: 'rotate(10deg) scale(1.1)', right:"-3%", },
            { transform: 'rotate(0deg) scale(1.1)', right:"-6%",  },
            {right:"-7%", zIndex:"10"},
            {right:"-10%", zIndex:"10"},
            {right:"-100%", zIndex:"10"},
          ];
          
          const timeing = {
            duration: 2000,
            iterations: 1,
          }
                    
          item.animate(selectItemEffect, timeing);
        //   setTimeout(()=>setPage("SKILLS"), 2000)  
        setPage("WORK")
          
          
    }

    const onWorksSelectedAnimationForMobile= ()=>{
        
        let item= work.current;//document.querySelector(".work")

        const selectItemEffect = [

            { transform: 'rotate(0) scale(1) translateX(1%)' },
            { transform: 'rotate(10deg) scale(1.1) translateX(3%)'},
            { transform: 'rotate(0) scale(1.1) translateX(6%)'  },
            { transform: 'translateX(7%)'  },
            { transform: 'translateX(10%)'  },
            { transform: 'translateX(100%)'  },
          ];
          
          const timeing = {
            duration: 2000,
            iterations: 1,
          }
                    
          item.animate(selectItemEffect, timeing);
          
        setPage("WORK")
          
          
    }

    const onContactSelectedAnimation= ()=>{
        
        let contactItem= contact.current;// document.querySelector(".contact")

        const selectItemEffect = [
            { transform: 'rotate(0deg) scale(1)', zIndex:"-1", left:"-1%", },
            { transform: 'rotate(-10deg) scale(1.1)', left:"-3%", },
            { transform: 'rotate(0deg) scale(1.1)', left:"-6%",  },
            {left:"-7%", zIndex:"-1"},
            {left:"-10%", zIndex:"-1"},
            {left:"-100%", zIndex:"-1"},
        ];
        
        const timeing = {
            duration: 2000,
            iterations: 1,
        }
        //   setTimeout(()=>skillsItem.classList.add("hidden") , 2000)  
                    
        contactItem.animate(selectItemEffect, timeing);
        //   setTimeout(()=>setPage("SKILLS"), 2000)  
        setPage("CONTACT")
                    
    }
    
    const onContactSelectedAnimationForMobile= ()=>{
        
        let contactItem= contact.current;// document.querySelector(".contact")

        const selectItemEffect = [

            { transform: 'rotate(0) scale(1) translateX(-1%)' },
            { transform: 'rotate(10deg) scale(1.1) translateX(-3%)'},
            { transform: 'rotate(0) scale(1.1) translateX(-6%)'  },
            { transform: 'translateX(-7%)'  },
            { transform: 'translateX(-10%)'  },
            { transform: 'translateX(-100%)'  },
        ];
        
        const timeing = {
            duration: 2000,
            iterations: 1,
        }
                    
        contactItem.animate(selectItemEffect, timeing);

        setPage("CONTACT")
                    
    }
    
    
    useEffect(()=>{
        
        const animated = about ? about.current : null;// document.querySelector('.about');
        console.log("NavigatorBox.js > useEffect", "props.page:"+props.page)
        if(animated) {
        
            if(!Helpers.ifMobileOrTabDevice()){

                animated.onanimationstart = ({animationName}) =>{
                    if(animationName==="fall") {
                        fixIt("index-fix")   
                    }
                }
                
                animated.onanimationend = ({animationName}) => {
    
                    
                    if(animationName==="putin-right" || animationName==="putin-left" ){
                        fixIt("")
                    }
    
                }
    
                const navigatorContainerAnimated= nav.current;// document.querySelector('.navigator-container');
    
                navigatorContainerAnimated.onanimationend = ({animationName}) => {
                    if(animationName==="fly")
                        // setContainerClasses("hidden")
                        navigatorContainerAnimated.classList.add("hidden")
                }
            }
        }

    }, [props.page])
    

    const resolveNavigatorContainerClasses= (c) => {
    
        let classes=["navigator-container"]
        
        if(page) {
            classes.push("animate-navigator-after-click")
        }
        
        if(c) {
            classes.push(c)
        }

        return classes.join(" ")
    }

    const resolveNavItemClasses= (c) => {
    
        let classes=["nav-item animate-item-after-click"]
        
        if(page && page.toLowerCase()===c) {
            classes.push("animate-item-after-click")
        }
        
        if(c) {
            classes.push(c)
        }

        return classes.join(" ")
    }
    
    const handleAnimationForMobileDevices = (event, fn) => {
      const animationName= event.nativeEvent.animationName

      if(fn==="START"){
        if(animationName==="fall") {
            // alert(animationName)
            // fixIt("index-fix") 
            skills.current.style.zIndex="10"  

        }
      } 
      
      if(fn==="END") { // 
        console.log("onAnimationEnd", animationName )
        if(animationName==="putin-right" || animationName==="putin-left" ){
            // fixIt("")
            skills.current.style.zIndex="1"  
        }

        if(animationName==="fly"){
            const navigatorContainerAnimated= nav.current;

            navigatorContainerAnimated.classList.add("hidden")
        }


      }
    }

    if(Helpers.ifMobileOrTabDevice()) { // Mobile / Tab
        return(
        
            <div 
                onAnimationEnd={(e)=>handleAnimationForMobileDevices(e, 'END')}
                ref={nav} 
                className={resolveNavigatorContainerClasses(containerClasses)} 
            >
    
                    <div ref={skills} className={'nav-item skills' }>
                        {/* <div className="overlay"></div> */}
                        <button onClick={()=> handelClick('SKILLS')} >{locale.nav.skills}</button>
                        {/* <span onClick={()=> handelClick('SKILLS')} >SKILLS</span> */}
    
                    </div>
    
                    <div 
                        ref={about} 
                        className={`nav-item about ${!Helpers.ifMobileOrTabDevice() ? zIndexFix : ""}`}
                        onAnimationStart={(e)=>handleAnimationForMobileDevices(e, 'START')} 
                        onAnimationEnd={(e)=>handleAnimationForMobileDevices(e, 'END')}
                    >
                        {/* <span className="fix-pesoudo-up-border"></span> */}
                        <button onClick={()=> handelClick("ABOUT")} >{locale.nav.about}</button>
                    </div>
    
                    <div ref={contact} className="nav-item contact">
                        {/* <span className="fix-pesoudo-down-border"></span> */}
                        <button onClick={()=> handelClick("CONTACT")} >{locale.nav.contact}</button>
                    </div>
    
                    <div ref={work} className="nav-item work">
                        <button onClick={()=> handelClick("WORK")} >{locale.nav.work}</button>
                    </div>
    
                {/* {
                    renderPage(page)
                } */}
            </div>
        )
    } else {// Desktop
        return(
        
            <div ref={nav} className={resolveNavigatorContainerClasses(containerClasses)}>
    
                    <div ref={skills} className={'nav-item skills' }>
                        {/* <div className="overlay"></div> */}
                        <button onClick={()=> handelClick('SKILLS')} >{locale.nav.skills}</button>
                        {/* <span onClick={()=> handelClick('SKILLS')} >SKILLS</span> */}
    
                    </div>
    
                    <div ref={about} className={`nav-item about ${zIndexFix}`}>
                        {/* <span className="fix-pesoudo-up-border"></span> */}
                        <button onClick={()=> handelClick("ABOUT")} >{locale.nav.about}</button>
                    </div>
    
                    <div ref={contact} className="nav-item contact">
                        {/* <span className="fix-pesoudo-down-border"></span> */}
                        <button onClick={()=> handelClick("CONTACT")} >{locale.nav.contact}</button>
                    </div>
    
                    <div ref={work} className="nav-item work">
                        <button onClick={()=> handelClick("WORK")} > {locale.nav.work} </button>
                    </div>
    
                {/* {
                    renderPage(page)
                } */}
            </div>
        )
    }


}
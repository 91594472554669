import React from 'react'
import back from '../../back.png'

export default  ( props ) => {

    return(
        <div className="page-header">

            <h1>{props.title}</h1>
            {/* <button onClick={()=>props.setPage(null)}>Back to menu</button> */}
            <div className="back-btn">
                    <img src={back} onClick={()=> props.setPage(null)} />
                    <span className="nav-label"  onClick={()=> props.setPage(null)} >{props.backTitle}</span>
            </div>
    </div>
    )
}
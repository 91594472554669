import React from "react";
import './index.css'
import CSS3 from './css3.png'
import MONGODB from './mongodb.png'
import HTML5 from './html5.png'
import NODEJS from './nodejs.png'
import ELECTRONJS from './electronjs.png'
import MYSQL from './mysql.png'
import RAILS from './rails.png'
import REACTJS from './reactjs.png'
import SQLITE3 from './sqlite3.png'
import JS from './javascript.png'
import UBNT from './ubuntu.png'
import WIN from './windows.png'
import GIT from './git.png'
import SLAC from './slac.png'
import VSCODE from './vscode.png'
import Item from "./Item";
// import back from '../../back.png'

export default ( props ) => {


    return(
        <div className={`skills-container ${props.className}` } >
            {props.children}
            <div className="be-db">
                <h2 className="group-title">{props.locale.skills.be}</h2>
                <div className="group">

                    <Item label={'Mongo-DB'} icon={MONGODB} />
                    <Item label={'MySql'} icon={MYSQL} />
                    <Item label={'Sqlite3'} icon={SQLITE3} />

                    <Item label={'Node + Express'} icon={NODEJS} />
                    <Item label={'Ruby + Rails'} icon={RAILS} />

                </div>
                
            </div>
            <div className="fe-mobile-desktop">
                <h2 className="group-title">{props.locale.skills.fe}</h2>

                <div className="group">

                    <Item label={'HTML5'} icon={HTML5} />
                    <Item label={'CSS3'} icon={CSS3} />
                    <Item label={'Javascript'} icon={JS} />

                    <Item label={'React + Native'} icon={REACTJS} />
                    <Item label={'NODE + Electron'} icon={ELECTRONJS} />
                    
                </div>
            
            </div>
                <div className="others">
                    <h2 className="group-title">{props.locale.skills.tools}</h2>

                    <div className="group">

                        <Item label={'Git/hub/lab'} icon={GIT} />
                        <Item label={'Windows'} icon={WIN} />
                        <Item label={'Ubuntu'} icon={UBNT} />

                        <Item label={'SLAC'} icon={SLAC} />
                        <Item label={'VsCode'} icon={VSCODE} />
                        
                    </div>
            </div>
            
        </div>
    )
}
export const ar= {
    ltc:"En",
    lang:"Ar",
    copyrights:"حقوق الطبع والنشر © 2022 جميع الحقوق محفوظة.",
    welcome:"مرحبا بك في موقعي الالكتروني :)",


    nav:{
        title:"القائمة",
        skills:"المهارات",
        about:"عن علي",
        work:"الاعمال",
        contact:"اتصل بي"
    },

    skills:{
        title:"مهاراتي",
        be:"الواجهة الخلفية وقواعد البيانات",
        fe:"الواجهة الامامية, الجوال وسطح المكتب",
        tools:"الادوات والمنصات"
    },

    about:{
        title:"معلومات عني",
        who:"نبذة مختصرة",
        p1:"أسمي علي وانا من العراق. حصلت على درجة البكلوريوس في الكيمياء عام 2006 ثم بدأت بتعلم البرمجة في عام 2012",
        p2:"ان العمل في اختصاصين مختلفين هو امر كان علي مواجته بعد ان تلقيت كورسات مكثفة في البرمجة ولحين اتخاذي القرار بالعمل في مجال البرمجيات",
        p3:"أعمل حاليا كمطور ويب للواجهتين الامامية والخلفية ومطور تطبيقات عابرة للنظام مع خبرة عملية ومعرفة بأحدث التقنيات والأدوات في هذا المجال. أقوم بإنشاء تطبيقات مستدامة تهدف إلى تحسين جودة الأعمال لعملائي",
        p4:"أعمل في مجال البرمجيات سواء للشركات أو كمستقل منذ عام 2016. لقد اسديت لي العديد من المهمات وتحت العديد من المسميات الوظيفية ، وكنت اقوم دائما بدوري كعضو نشط و / أو قائد فريق",
        why:"لماذا تستعين بخدماتي؟",
        why_intro:".بجانب معرفتي التقنية اني ايضا جيد في",
        ap:"المنظور تحليلي",
        pp:"التخطيط الاحترافي",
        ps:"حل المعضلات البرمجية"
  
    },

    contact:{
        title:"اتصل بي",
        sm_hint:"تفضل بالتواصل معي على شبكات التواصل ادناه ;)",
        email_hint:"أو ببساطة ارسل لي بريدا الكترونيا بملء النموذج ادناه",
        email_placeholder:"عنوان البريد الالكتروني",
        email_body_ph:"محتوى الرسالة يكتب هنا...", 
        send_email:"ارسل الى علي",
        email_sent:"تم الارسال بنجاح, سأرد عليك في اقرب وقت",
        email_not_sent:"لم يتم ارسال البريد, يرجى التأكد من اتصالك بالانترنت"
    },
    work:{
        title:"بعض اعمالي",
        item1:"الموقع الالكتروني لشركة البرمجيات الناشئة سابقا Software You Need ( SYN ).",
        item2:"تطبيق منشأ بلغة  Ruby  يجمع البيانات من موقع  gsmarena.com ويساعد المستخدمين على البحث عن معلومات الهواتف والالواح الذكية.",
        item3:"موقع الكتروني مختص بالتجارة الالكترونية والتوصيل الى المنزل لصالح Sev Market.",
        view:"معاينة"

    },
  }

import React, { useState } from "react";
import './index.css'
import SocialItem from "./SocialItem";
// import {SMTPClient} from 'smtp-client';
// import {Email} from './smtp.js'
import { HOST, USER, PSWD, RECPT, PORT } from "./stmp-data";
export default ( props ) => {
    
    const [sender, setSender ]= useState(null)
    const [body, setBody ]= useState(null)

    const sendEmail = async (event) => {
        event.preventDefault()
        try {
            window.Email.send({
                    Host: HOST,
                    Username : USER,
                    Password : PSWD,
                    To : RECPT,
                    From : RECPT,
                    Subject : sender,
                    Body : body,
                }).then(
                    message => {
                        if(message==="OK") {
                            alert(props.locale.contact.email_sent)
                        } else {
                            console.log("smtp message:", message)
                            alert(props.locale.contact.email_not_sent)
                        }
                        
                }
            );
        } catch (error) {
           console.log("Someting went Wrong!")   
           alert(error)
        }
    }

    const dir= props.locale.lang.toLowerCase().startsWith("en") ? "ltr" :"rtl"
    
    return(
        <div  className={`contact-container ${props.className}` }>
            {props.children}
            <h3>{props.locale.contact.sm_hint}</h3>
            <div className="social-items">
                <SocialItem name="facebook"/>
                {/* <SocialItem name="twitter"/> */}
                {/* <SocialItem name="instaram"/> */}
                <SocialItem name="linkedin"/>
                <SocialItem name="whatsapp"/>
            </div>
            <h3>{props.locale.contact.email_hint}</h3>
            <form dir={dir} onSubmit={(event)=> sendEmail(event)} className="mail-form">
                <span className={dir==="rtl" ? "required-email-rtl" :"required-email"}>*</span>
                <input required placeholder={props.locale.contact.email_placeholder} type={'email'} onChange={({target})=> setSender(target.value)} />
                <span className={dir==="rtl" ? "required-body-rtl" :"required-body"}>*</span>
                <textarea  required placeholder={props.locale.contact.email_body_ph} onChange={({target})=> setBody(target.value)} />
                <input type={'submit'} onClick={null} value={props.locale.contact.send_email} />
            </form>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import FB from './icons/fb.png'
import TW from './icons/tw.png'
import INS from './icons/ins.png'
import WP from './icons/wp.png'
import LIN from './icons/lin.png'

export default ( props ) => {
    
    const [showupAnimation, setShowupAnimationClass]= useState('')
    useEffect(()=> setShowupAnimationClass('animate__rubberBand'), [])
    const resolveIcon =  (value) => {
        switch (props.name) {
            case "facebook":
                return value==="IMG" ? FB :"https://fb.me/softwareofthings"
                
                // return value==="IMG" ? FB :"https://www.facebook.com/ali.abed.mohsen/"
            case "twitter":
                
                return  value==="IMG" ? TW :"https://twitter.com"

            case "linkedin":
                
                return  value==="IMG" ? LIN :"https://www.linkedin.com/in/aliabedmohsen/"

            case "whatsapp":
                
                return  value==="IMG" ? WP :"https://wa.me/message/PNZJME57YGI7O1"

            case "instaram":
                return  value==="IMG" ? INS :"https://www.instagram.com/ali.abed.mohsen/"

                break;
                
            default:
                break;
        }
    }
    
    return (
        // <AnimationOnScroll animateIn="animate__bounceIn">

            <div className={`social-item ${showupAnimation} `} >
                <a href={resolveIcon("LINK")} target={'__blank'}>
                    <img className={""} src={resolveIcon("IMG")} />
                </a>
            </div>
        // </AnimationOnScroll>
    )
} 
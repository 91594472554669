import React, {useEffect, useRef, useState} from 'react';
// import logo from './logo2-dark.png';
import logo from './logo3.png';

import "animate.css/animate.min.css"; // use it globaly; no need to import again.

import './App.css';

import NavigatorBox from './views/NavigatorBox';

import Footer from './views/footer'

import Page from './views/Page';

import Helpers from './Helpers';
// import './overlay.css'
import Localizer from './locales';

function App() {
  
  const [page, setPage]= useState(null)

  const [lang, setLang]= useState("en")
  
  const introOverlay = useRef(null)
  
  const appLogo = useRef(null)

  const welcome = useRef(null)
  

  const changeLang= () => {
    Localizer.setLanguage(Localizer.strings.ltc.toLocaleLowerCase())
    setLang(Localizer.getLanguage())
  }
  
  useEffect(()=> {
     let dlang= Localizer.getDeviceLanguage()
     Localizer.setLanguage(dlang)
     setLang(Localizer.getLanguage())
  }, [])

  useEffect(()=> {
    // const animated = document.querySelector('.intro-overlay');
    console.log("App.js > useEffect")
    
    if( introOverlay && !Helpers.ifMobileOrTabDevice()) { 
      
      console.log("introOverlay is ", introOverlay) 
      
      introOverlay.current.onanimationstart = ({animationName}) => {
          
          console.log("introOverlay.current.onanimationstart")
          
          if(animationName==="fadeout1") {
              // let logo = document.querySelector('.App-logo');
              appLogo.current.classList.add("hidden")
          }
      }
      
      introOverlay.current.onanimationend = ({animationName}) => {
        
          console.log("introOverlay.current.onanimationend")
          
          if(animationName==="fadeout1" ){
          
            introOverlay.current.classList.add("hidden")

          }

          if(animationName==="logo-welcome-animation") {
            // let logo = document.querySelector('.App-logo');
            appLogo.current.classList.remove("hidden")

          }

          if(animationName==="fadeout2") {
            // let logo = document.querySelector('.welcome');
            welcome.current.classList.add("hidden")
          
          }

      }
    }
  }, [])
  
  const handleAnimation= (event, animateType) => {

     const animationName= event.nativeEvent.animationName
    //  alert(animationName)
    if(animateType==="START"){
      if(animationName==="fadeout1") {
        // let logo = document.querySelector('.App-logo');
        appLogo.current.classList.add("hidden")
    }

    } else { // END
        


        if(animationName==="logo-welcome-animation") {
          // let logo = document.querySelector('.App-logo');
          appLogo.current.classList.remove("hidden")

        }

        if(animationName==="fadeout2") {
          // let logo = document.querySelector('.welcome');
          welcome.current.classList.add("hidden")
        
        }

        if(animationName==="fadeout1" ){
            
          introOverlay.current.classList.add("hidden")

        }
    }

  }
  

  return (
    <div className="App">

      <header className="App-header">
        <img ref= {appLogo} src={logo} className="App-logo" alt="logo" />
        <button className='lang' onClick={()=> changeLang() }>{Localizer.strings.ltc.toLocaleLowerCase()}</button>
      </header>
    
      <NavigatorBox lang={lang} setPage={setPage} page={page} />

      <Page setPage={setPage} page={page} lang={lang} />
      
      <Footer />
      
      {/* <IntroOverlay ref={introRefs}/> */}
      {
        Helpers.ifMobileOrTabDevice() ? 
          <div
            ref={introOverlay} 
            className={'intro-overlay'}
            onAnimationEnd={(e) => handleAnimation(e, "START")}
            onAnimationStart={(e) => handleAnimation(e, "END")}
          >
           
            <div className='slide top-left'></div>
          
            {/* <div className='slide top-right'></div> */}
          
            {/* <div className='slide bottom-left'></div> */}
          
            {/* <div className='slide bottom-right'></div> */}
          
            <div className='intro-logo'><img src={logo} className="" alt="logo" /></div>
          
            <span ref={welcome} className='welcome'>{Localizer.strings.welcome}</span>
        
          </div>
        : 
          <div
            ref={introOverlay} 
            className={'intro-overlay'}
          >
          
            {/* <div className='slide top-left'></div> */}
          
            {/* <div className='slide top-right'></div> */}
          
            {/* <div className='slide bottom-left'></div> */}
          
            {/* <div className='slide bottom-right'></div> */}
          
            <div className='intro-logo'><img src={logo} className="" alt="logo" /></div>
          
            <span ref={welcome} className='welcome'>{Localizer.strings.welcome}</span>
        
          </div>
      }
 
      
    </div>
  )

}

// const IntroOverlay= React.forwardRef((props, ref) => {


//   return (
//     <div  ref={ref.intro} className='intro-overlay'>
//       <div className='slide top-left'></div>
//       <div className='slide top-right'></div>
//       <div className='slide bottom-left'></div>
//       <div className='slide bottom-right'></div>
//       <div className='intro-logo'><img src={logo} className="" alt="logo" /></div>
//       <span ref={ref.welcome} className='welcome'>Welcome to my website :)</span>
//     </div>
//   )
// })

export default App;

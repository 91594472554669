export const en= {
    ltc:"عربي",
    lang:"En",
    copyrights:"Copyright © 2022 All Rights Reserved.",
    welcome:"Welcome to my website :)",
    nav:{
        title:"Navigation",
        skills:"SKILLS",
        about:"ABOUT",
        work:"WORK",
        contact:"CONTACT"
    },
    skills:{
      title:"SKILLS",
      be:"Backend & Database",
      fe:"Frontend, Mobile & Desktop",
      tools:"Tools & Platforms"

    },

    about:{
      title:"ABOUT ME",
      who:"Who am I?",
      p1:"My name is Ali and I'm from Iraq. I got the bachelor degree in chemistry early in 2006, before I started learning coding in 2012.",
      p2:"Having more than one specialty is something I had to face in the very beginning of my career journey after taking an extensive online learning in coding before I make the decision to work in software engineering.",
      p3:"I currently work as a full-stack web developer and cross-platform app developer with hands on experience and knowledge of latest techs and tools in the field. I create sustainable apps that are meant to improve business quality of my customers.",
      p4:"I have been working in software engineering whether for companies or as freelancer since 2016. I have been enrolled in various roles and under many job titles, and I was always play as an active member and/or team leader.",
      why:"Why to hire me?",
      why_intro:"Beside technical knowledge, I'm also prety known for..",
      ap:"Analytical Perspective",
      pp:"Professional Planning",
      ps:"Problem Solving"
    },

    contact:{
       title:"CONTACT ME",
       sm_hint:"Contact me on these social networks ;)",
       email_hint:"Or simply send me an email by filling the form..",
       email_placeholder:"Real working email address",
       send_email:"Send to Ali",
       email_body_ph:"Your query stands here...",
       email_sent:"Mail sent successfully! Ali will respond to your query soon.",
       email_not_sent:"Message could not be sent! please make sure connected to the Internet."
 
    },
    work:{
        title:"WORK",
        item1:"A bussiness website for the software startup Software You Need ( SYN ).",
        item2:" Ruby-Cli based app scrapes data from gsmarena.com, and helps users to search for smartphone features...",
        item3:"An e-commerce website for the startup Sev Market.",
        view:"View"
    },

}
